/* A default value for the env variable. */
export const env: '1' | '2' | '3' | '4' | '5' = process.env.REACT_APP_ENV
  ? (process.env.REACT_APP_ENV as '1' | '2' | '3' | '4' | '5')
  : '3';
// 1 - prod
// 2 - stage
// 3 - test
// 4- preview
// 5 - local

export const configObject = {
  '1': {
    masterLayoutEnv: '1',
    azureConnection: '',
    name: 'AnnalyzeCU Prod',
    id: 'analyzecu_prod',
    postLoginLink: 'https://app.cucollaborate.com',
    api: {
      core: 'https://analyzecu.cucollaborate.com',
      dataManagment:
        'https://analyzecu-data-management-web-api.cucollaborate.com',
      lid: 'https://analyzecu-lid-web-api.cucollaborate.com',
      cdfi: 'https://analyzecu-cdfi-web-api.cucollaborate.com',
    },
    indentityUrl: 'https://auth.cucollaborate.com',
    Windows: '/api/datamanagement/DataPreparer?os=win',
    Mac: '/api/datamanagement/DataPreparer?os=mac',
  },

  '2': {
    masterLayoutEnv: '2',
    azureConnection: '',
    name: 'AnnalyzeCU Staging',
    id: 'analyzecu_staging',
    postLoginLink: 'https://staging1.cucollaborate.com',
    api: {
      core: 'https://analyzecu-staging.cucollaborate.com',
      dataManagment:
        'https://analyzecu-data-management-web-api-stage.cucollaborate.com',
      lid: 'https://analyzecu-lid-web-api-stage.cucollaborate.com',
      cdfi: 'https://analyzecu-cdfi-web-api-stage.cucollaborate.com',
    },
    indentityUrl: 'https://authstaging.cucollaborate.com',
    Windows: '/api/datamanagement/DataPreparer?os=win',
    Mac: '/api/datamanagement/DataPreparer?os=mac',
  },
  '3': {
    masterLayoutEnv: '3',
    azureConnection: '',
    name: 'AnnalyzeCU Test',
    id: 'analyzecu_test',
    postLoginLink: 'https://testqa.cucollaborate.com',
    api: {
      dataManagment:
        'https://analyzecu-data-management-web-api-test.cucollaborate.com',
      core: 'https://analyzecu-testqa.cucollaborate.com',
      lid: 'https://analyzecu-lid-web-api-test.cucollaborate.com',
      cdfi: 'https://analyzecu-cdfi-web-api-test.cucollaborate.com',
    },
    indentityUrl: 'https://authtestqa.cucollaborate.com',
    Windows: '/api/datamanagement/DataPreparer?os=win',
    Mac: '/api/datamanagement/DataPreparer?os=mac',
  },
  '4': {
    masterLayoutEnv: '4',
    azureConnection: '',
    name: 'AnnalyzeCU Test',
    id: 'analyzecu_test',
    postLoginLink: 'https://testqa.cucollaborate.com',
    api: {
      core: process.env.REACT_APP_API_SERVER || '',
      lid: 'https://analyzecu-lid-web-api-test.cucollaborate.com',
      dataManagment:
        'https://analyzecu-data-management-web-api-test.cucollaborate.com',
      cdfi: 'https://analyzecu-cdfi-web-api-test.cucollaborate.com',
    },
    indentityUrl: 'https://authtestqa.cucollaborate.com',
    Windows: '/api/datamanagement/DataPreparer?os=win',
    Mac: '/api/datamanagement/DataPreparer?os=mac',
  },
  '5': {
    masterLayoutEnv: '4',
    azureConnection: '',
    name: 'AnnalyzeCU Test',
    id: 'analyzecu_localhost',
    postLoginLink: 'https://testqa.cucollaborate.com',
    api: {
      core: 'https://analyzecu-testqa.cucollaborate.com',
      dataManagment:
        'https://analyzecu-data-management-web-api-test.cucollaborate.com',
      lid: 'https://analyzecu-lid-web-api-test.cucollaborate.com',
      cdfi: 'https://analyzecu-cdfi-web-api-test.cucollaborate.com',
    },
    indentityUrl: 'https://authtestqa.cucollaborate.com',
    Windows: '/api/datamanagement/DataPreparer?os=win',
    Mac: '/api/datamanagement/DataPreparer?os=mac',
  },
};

export const appConfig = configObject[env];
