/* eslint-disable */
import React, { FC, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import s from './chart.module.scss';
import ButtonWithEye from '../Button';
import { CSSTransition } from 'react-transition-group';

type DataItem = {
  name: String;
  value: any[];
  tooltipText: string;
};

type DataSeries = {
  data: DataItem[];
  lineStyle: any;
};

type ChartProps = {
  series: DataSeries[];
  medianValue: number;
  title: string;
  isLID: boolean;
  isRed?: boolean;
};

const Chart: FC<ChartProps> = ({
  series,
  medianValue,
  title,
  isLID,
  isRed = true,
}) => {
  const dataOptions = {
    hour12: false,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions;
  const redColor = isRed ? '#f35461' : '#F9A31D';

  const [isLegendVisible, setIsLegendVisible] = useState<boolean>(true);
  const options = {
    tooltip: {
      trigger: 'item',
      padding: 0,
      appendToBody: true,
      enterable: false,
      confine: false,
      axisPointer: {
        type: 'shadow', // The default is a straight line, optional:'line' |'shadow'|'cross' cross is a mixed display
        shadowStyle: {
          color: 'rgba(127,196,251,0.3)',
        },
      },
      formatter(params: any) {
        const date = new Date(params.name).toLocaleString('en-US', dataOptions);
        const cusSpan = +params.value[1];
        const toolTipText = params.data.tooltipText;
        return (
          `<div style='background:${
            params.data.itemStyle?.background
              ? params.data.itemStyle?.background
              : +cusSpan.toFixed(2) > 50
              ? '#7BD5A7'
              : +cusSpan.toFixed(2) >= 45 && +cusSpan.toFixed(2) <= 50
              ? '#F9A31D'
              : '#f35461'
          }' class="tooltip-div">` +
          `<div><span class="tooltip-month">${date}</span>
          <h2 class="tooltip-value">${cusSpan.toFixed(2)}%</h2></div>` +
          `<h2 class="tooltip-text">${toolTipText}</h2></div>` +
          '</div>'
        );
      },
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 10,
      },
    ],
    xAxis: {
      type: 'category',
      inverse: true,
      splitLine: {
        show: false,
        boundaryGap: false,
      },
      splitNumber: 2,
      axisLabel: {
        margin: 40,
        color: '#7A89A7',
        fontFamily: 'Open Sans',
        showMinLabel: true,
        showMaxLabel: true,
      },
      axisLine: {
        lineStyle: {
          color: 'transparent',
        },
      },
    },
    visualMap: {
      show: false,
      dimension: 1,
      pieces: [
        {
          lte: medianValue,
          color: '#F9A31D',
        },
        {
          lte: 45,
          color: '#f35461',
        },
        {
          gt: medianValue + 0.01,
          // ToDo If we need smooth line fradient the best solution is use epsilon value
          //  gt: 50 + Number.EPSILON * 100,
          color: '#7BD5A7',
        },
      ],
    },
    yAxis: {
      min: 0,
      axisLabel: {
        margin: 40,
        color: '#7A89A7',
        fontFamily: 'Open Sans',
      },
      max: 100,
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ECE9F1'],
          width: 1.2,
          type: 'dashed',
        },
      },
    },
    grid: {
      left: 95,
      top: 50,
      right: 75,
      bottom: 100,
    },
    series: series.map(({ data, lineStyle }) => ({
      type: 'line',
      smoothMonotone: 'null',
      animate: true,
      smoothConstraint: true,
      showSymbol: true,
      smooth: true,
      symbolSize: 8,
      symbol: `emptyCircle`,
      select: {
        selectedMode: 'single',
        itemStyle: {
          borderWidth: 15,
          shadowBlur: 51.1,
        },
      },
      hoverAnimation: true,
      markLine: {
        silent: true,
        symbol: ['none', 'none'],
        label: { show: false },
        precision: 0,
        zlevel: 0,
        z: 0,
        lineStyle: {
          color: '#C2CEE0',
          type: 'dashed',
          opacity: 1,
          width: 2.5,
        },
        data: [
          {
            yAxis: medianValue + 1,
          },
        ],
      },
      emphasis: {
        focus: 'series',
        blurScope: 'coordinateSystem',
      },
      animation: false,
      lineStyle,
      data,
    })),
  };

  return (
    <div className={`${s.chart}`}>
      <div className={`${s.chart__header}`}>
        <div className={`${s.chart__header__item}`}>
          <h4 className={`${s.chart__header__item__title}`}>{title}</h4>
        </div>
        <div className={`${s.chart__header__item}`}>
          <div className={`${s.chart__header__item__legend}`}>
            <ButtonWithEye
              title={isLegendVisible ? 'Hide Legend' : 'Show Legend'}
              isOpen={isLegendVisible}
              onClick={() => setIsLegendVisible(!isLegendVisible)}
            />
          </div>
        </div>
      </div>
      <CSSTransition
        in={isLegendVisible}
        timeout={500}
        classNames="my-node"
        unmountOnExit
      >
        <div className={`${s.legend}`}>
          <div className={`${s.legend__wrapper}`}>
            {isLID ? (
              <div className={`${s.lel} col-sm-6 p-0`}>
                <div className={`${s.legend__wrapper__item}`}>
                  <span
                    className={`${s.legend__wrapper__item__line}
                   ${s.legend__wrapper__item__line__dash}
                     ${s.legend__wrapper__item__line__grey}`}
                  />
                  <span className={`${s.legend__wrapper__item__text}`}>
                    Members needed to qualify for low-income designation
                  </span>
                </div>
                <div className={`${s.legend__wrapper__item}`}>
                  <span
                    className={`${s.legend__wrapper__item__line}
                   ${s.legend__wrapper__item__line__solid}
                     ${s.legend__wrapper__item__line__red}`}
                  />
                  <span className={`${s.legend__wrapper__item__text}`}>
                    LID status not met
                  </span>
                </div>
                <div className={`${s.legend__wrapper__item}`}>
                  <span
                    className={`${s.legend__wrapper__item__line}
                   ${s.legend__wrapper__item__line__solid}
                     ${s.legend__wrapper__item__line__orange}`}
                  />
                  <span className={`${s.legend__wrapper__item__text}`}>
                    Close to meeting LID Status
                  </span>
                </div>
                <div className={`${s.legend__wrapper__item}`}>
                  <span
                    className={`${s.legend__wrapper__item__line}
                   ${s.legend__wrapper__item__line__solid}
                     ${s.legend__wrapper__item__line__green}`}
                  />
                  <span className={`${s.legend__wrapper__item__text}`}>
                    Pre-Qualified
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className={`${s.lel} col-sm-6 p-0`}>
                  <h4 className={`${s.legend__wrapper__item__title}`}>
                    Number of Loans
                  </h4>
                  <div className={`${s.legend__wrapper__item}`}>
                    <span
                      className={`${s.legend__wrapper__item__line}
                   ${s.legend__wrapper__item__line__dash}
                     ${s.legend__wrapper__item__line__grey}`}
                    />
                    <span className={`${s.legend__wrapper__item__text}`}>
                      Level needed to meet qualification requirements
                    </span>
                  </div>
                  <div className={`${s.legend__wrapper__item}`}>
                    <span
                      className={`${s.legend__wrapper__item__line}
                   ${s.legend__wrapper__item__line__solid}
                     ${s.legend__wrapper__item__line__red}`}
                    />
                    <span className={`${s.legend__wrapper__item__text}`}>
                      Status not met
                    </span>
                  </div>
                  <div className={`${s.legend__wrapper__item}`}>
                    <span
                      className={`${s.legend__wrapper__item__line}
                   ${s.legend__wrapper__item__line__solid}
                     ${s.legend__wrapper__item__line__green}`}
                    />
                    <span className={`${s.legend__wrapper__item__text}`}>
                      Pre-Qualified
                    </span>
                  </div>
                </div>
                <div className={`${s.lel} col-sm-6 p-0`}>
                  <h4 className={`${s.legend__wrapper__item__title}`}>
                    Dollar amount
                  </h4>
                  <div className={`${s.legend__wrapper__item}`}>
                    <span
                      className={`${s.legend__wrapper__item__line}
                   ${s.legend__wrapper__item__line__dash}
                     ${s.legend__wrapper__item__line__grey}`}
                    />
                    <span className={`${s.legend__wrapper__item__text}`}>
                      Level needed to meet qualification requirements
                    </span>
                  </div>
                  <div className={`${s.legend__wrapper__item}`}>
                    <span
                      className={`${s.legend__wrapper__item__line}
                   ${s.legend__wrapper__item__line__dash}
                     ${s.legend__wrapper__item__line__red}`}
                    />
                    <span className={`${s.legend__wrapper__item__text}`}>
                      Status not met
                    </span>
                  </div>
                  <div className={`${s.legend__wrapper__item}`}>
                    <span
                      className={`${s.legend__wrapper__item__line}
                   ${s.legend__wrapper__item__line__dash}
                     ${s.legend__wrapper__item__line__green}`}
                    />
                    <span className={`${s.legend__wrapper__item__text}`}>
                      Pre-Qualified
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </CSSTransition>
      <div className={`${s.chart__main}`}>
        <div style={{ flex: 'auto' }}>
          <ReactECharts
            option={options}
            notMerge={true}
            lazyUpdate={true}
            style={{ marginTop: '4px', height: '100%' }}
            opts={{ renderer: 'canvas' }}
          />
        </div>
      </div>
    </div>
  );
};
export default Chart;
