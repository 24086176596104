import { createHttpClient } from './createHttpClient';
import { appConfig } from '../constants';

const coreHttpClient = createHttpClient({ baseURL: appConfig.api.core });
const lidHttpClient = createHttpClient({ baseURL: appConfig.api.lid });
const cdfiHttpClient = createHttpClient({ baseURL: appConfig.api.cdfi });
const dataManagmentHttpClient = createHttpClient({
  baseURL: appConfig.api.dataManagment,
});

export {
  coreHttpClient,
  lidHttpClient,
  cdfiHttpClient,
  dataManagmentHttpClient,
};
